.navigation-btns {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    button {
        border-radius: 0;
        width: 50%;
    }
}
