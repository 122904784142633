.form-selection-container {
    .custom-radio-btn-container {
        label {
            display: inline-block;
            margin: 0;
            padding: 0 0.5rem;
        }
        .single-radio-wrapper {
            margin-bottom: 1rem;
            position: relative;
            border-radius: 10px;
            overflow: hidden;
            .custom-radio-btn {
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 0;
                &:hover {
                }
                span {
                    visibility: hidden;
                }
            }
            .Mui-checked {
                border: 3px solid gray;
                border-radius: 10px;
            }
        }
    }
}
